<template>
    <div class="content">
      <div class="row">
       
        <div class="col-md-12">
           <vuestic-widget :headerText="'Datos Erroneos'" v-show='status===0'>
            <div class="row">
              <div class="col-md-12">
              <h1>Asegurate de que haz escrito correctamente la URL</h1>
              </div>
  
            </div>
          </vuestic-widget>
             <vuestic-widget :headerText="'Ranking del Evento'" v-show='status===1'>
            <div class="row">
                <div class="col-md-12">
                    <img class="rounded mx-auto d-block" style="max-width: 200px;" src="https://storage.googleapis.com/ticktaps-school/production/schools/school1/evaluations/evaluation1/solutions/student1/20220914_171357_439628_url.png?authuser=1"></img>
            <br></br>
                </div>
              <div class="col-md-6">
                    <table class="table table-striped">
                    <thead>
                        <tr>
                        
                        <th scope="col" style="font-size:22px;">Nombre</th>
                        <th scope="col" style="font-size:22px;">Unidades recargadas</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="topup in topups" :key="topup.id">
                    
                        <td style="font-size:22px;">{{topup.name}}</td>
                        <td style="font-size:22px;"><span v-if="topup.total_topup">{{topup.total_topup[0].total + topup.credit}}</span></td>
                        </tr>
                    
                    </tbody>
                    </table>
            </div>
            <div class="col-md-6">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        
                        <th scope="col" style="font-size:22px;">Nombre</th>
                        <th scope="col" style="font-size:22px;">Visitas</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="ticket in tickets" :key="ticket.id">
                    
                        <td style="font-size:22px;">{{ticket.customer.name}}</td>
                        <td style="font-size:22px;"><span v-if="ticket.log_access">{{ticket.log_access}}</span></td>
                        </tr>
                    
                    </tbody>
                    </table>
            </div>
            </div>
          </vuestic-widget>
          
       
         
        </div>
      
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import Multiselect from "vue-multiselect";
  //import TableCustomer from "../tables/TableCustomer/TableCustomer.vue";
  
  import { SpringSpinner } from "epic-spinners";
  import { mapState, mapActions } from "vuex";
  export default {
    name: "PublicRegister",
    components: {
      SpringSpinner,
      // TableCustomer,
      Multiselect
    },
    mounted() {
  
      // checkbox and radios\
      var id_event = this.$route.params.idevent;
      id_event = atob(id_event)
       // let id_event = 77;

      this.loadEventAnalitics(id_event)
        .then(data => {
          
          this.topups = data.topups;

          this.topups.sort((a,b) => (b.total_topup[0].total + b.credit) -(a.total_topup[0].total + a.credit)); 

         
          this.tickets = data.tickets;

          this.tickets.sort((a,b) => (b.log_access - a.log_access)); 
        
        })
        .catch(data => {
          console.log(data);
  
          this.addToastMessage({
            text: "Error consultando usuarios",
            type: "warning"
          });
        });
    },
    computed: {
      ...mapState({
      }),
      isSuccessfulNameValid() {
        let isValid = false;
        if (this.formFields.name) {
          isValid = this.formFields.name.validated && this.formFields.name.valid;
        }
        return isValid;
      },
      isSuccessEmailValid() {
        let isValid = false;
        if (this.formFields.email) {
          isValid =
            this.formFields.email.validated && this.formFields.email.valid;
        }
        return isValid;
      }
    },
    data() {
      return {
        header: "Nuevo Cliente",
        typecustomeroptions: null,
        code: "",
        name_event:"",
        name: "",
        email: "",
        status:1,
        idevent:"",
        //Optional Inputs
        phone: "",
        address: "",
        gender: "",
        genderoptions: [
          { name: "Mujer", id: 1 },
          { name: "Hombre", id: 2 },
          { name: "Otro", id: 3 }
        ],
        twitter:"",
        facebook:"",
        instagram:"",
        birthday: "",
        analitics:{},
        tickets:{},
        topups:{},
        //----
        idtypecustomer: "",
        active: 1,
        eventoptions: null
      };
    },
    methods: {
      ...mapActions([
        "loadEventAnalitics",
        "storeCustomer",
        "addToastMessage"
      ]),
      backPage: function() {
        this.$router.go(-1);
      },
      validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      saveCustomer() {
  
        if(!this.validateEmail(this.email)){
           this.addToastMessage({
                  text: 'Indica un correo válido',
                  type: "warning"
                });
        }
        else{
        this.$validator.validate().then(result => {
          if (result) {
            this.storeCustomer({
             // code: this.code,
              name: this.name,
              email: this.email,
              phone: this.phone,
              address: this.address,
              gender: this.gender,
              birthday: this.birthday,
  
              active: this.active,
              id_event: this.idevent,
              id_type_customer: 1,
              instagram:this.instagram,
              facebook:this.facebook,
              twitter:this.twitter
            })
              .then(data => {
                this.addToastMessage({
                  text: data.message,
                  type: "success"
                });
                setTimeout(() => this.$router.replace("/registered/"+data.data.code), 2000);
              })
              .catch(data => {
                this.error = data.message;
                this.addToastMessage({
                  text: data.message,
                  type: "warning"
                });
              });
          }
        });
         }
      }
    }
  };
  </script>
  
  <style lang="scss">
  .color-icon-label-table {
    td:first-child {
      width: 1rem;
    }
  }
  </style>
  
  